import axios from './BaseService'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/lotteries`

export async function getLotteries(token, page, limit, status) {
  const endPoint = `${ENDPOINT}/listlotteries${page ? `?page=${page}` : ''}`
  const response = await axios.post(endPoint, { status, limit })
  return response.data
}

export async function getLottery(token, id) {
  const endPoint = `${ENDPOINT}/lottery/${id}`
  const response = await axios.get(endPoint)
  return response.data
}

export async function getTimezone(token) {
  const endPoint = `${ENDPOINT}/timezones`
  const response = await axios.get(endPoint)
  return response.data
}

export async function doEditLottery(token, post) {
  const endPoint = `${ENDPOINT}/editlottery/${post.id}`
  const response = await axios.put(endPoint, post)
  return response.data
}

export async function createLottery(token, post) {
  const endPoint = `${ENDPOINT}/addlottery`
  const response = await axios.post(endPoint, post)
  return response.data
}

export const editImg = async (token, id, data) => {
  const endPoint = `${ENDPOINT}/picture/${id}`
  const response = await axios.post(endPoint, data)
  return response.data
}

export async function createGamePackage(token, post) {
  const endPoint = `${ENDPOINT}/addgamepackage`
  const response = await axios.post(endPoint, post)
  return response.data
}

export async function doEditGamePackage(token, post) {
  const endPoint = `${ENDPOINT}/editgamepackage/${post.id}`
  const response = await axios.put(endPoint, post)
  return response.data
}

export async function getGamePackages(token, mainLotteryId) {
  const endPoint = `${ENDPOINT}/gamepackages/${mainLotteryId}`
  const response = await axios.get(endPoint)
  return response.data
}

export async function createDayAward(token, post) {
  const endPoint = `${ENDPOINT}/adddayaward`
  const response = await axios.post(endPoint, post)
  return response.data
}

export async function doEditDayAward(token, post) {
  const endPoint = `${ENDPOINT}/editdayaward/${post.id}`
  const response = await axios.put(endPoint, post)
  return response.data
}

export async function getDayAwards(token, mainLotteryId) {
  const endPoint = `${ENDPOINT}/dayawards/${mainLotteryId}`
  const response = await axios.get(endPoint)
  return response.data
}

export async function getResults(token, page, limit, mainLotteryId) {
  const endPoint = `${ENDPOINT}/results${page ? `?page=${page}` : ''}`
  const response = await axios.post(endPoint, { mainLotteryId, limit })
  return response.data
}
