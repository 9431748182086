/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
// import Menu from '../../components/Menu/Menu'
import {
  createLottery,
  doEditLottery,
  getDayAwards,
  getGamePackages,
  getLottery,
  getTimezone,
} from '../../services/LotteriesService'
import { i18n } from '../../utils/translates/i18n'
import DayAwardRow from './DayAwardRow.js'
import GamePackageRow from './GamePackageRow'
import ModalDayAward from './ModalDayAward.js'
import ModalGamePackage from './ModalGamePackage'
import { decrypt } from '../../utils/crypto.js'

export default function CreateEditLottery() {
  const rule = decrypt(sessionStorage.getItem('rule'))
  const param = useParams()
  const token = sessionStorage.getItem('token')
  const history = useHistory()

  const [gamePackages, setGamePackages] = useState([])
  const [dayAwards, setDayAwards] = useState([])
  const [timezones, setTimezones] = useState([])
  const [editGamePackage, setEditGamePackage] = useState({})
  const [editDayAward, setEditDayAward] = useState({})

  const doEditGamePackage = lottery => {
    setEditGamePackage(lottery)
  }

  const doEditDayAward = lottery => {
    setEditDayAward(lottery)
  }

  const DEFAULT_POST = {
    id: '',
    lottery: '',
    ballsStart: 0,
    ballsFinish: 50,
    qtyToAward: 5,
    oddLimit: 0,
    img: '',
    timezone: 37,
    status: 4,
    youtubeChannelId: '',
    youtubeLink: '',
    liveHour: new Date().getHours(),
  }
  const [post, setPost] = useState(DEFAULT_POST)

  const saveLottery = async () => {
    if (post.id) {
      const success = await doEditLottery(token, post)
      if (success.status) {
        toast.success(i18n.t('lottery_updated'))
        setPost(success.editedLottery)
        doGetGamePackages(success.editedLottery?.id)
      }
    } else {
      const success = await createLottery(token, post)
      if (success.status) {
        toast.success(i18n.t('lottery_created'))
        window.location.href = `/lottery/edit/${success.newLottery?.id}`
      }
    }
  }

  const doGetTimezones = async token => {
    const success = await getTimezone(token)
    if (success.status) {
      setTimezones(success.timezones)
    }
  }

  const doGetLottery = async id => {
    const success = await getLottery(token, id)
    if (success.status) {
      setPost(success.lottery)
    }
  }

  const doGetGamePackages = async mainLotteryId => {
    const success = await getGamePackages(token, mainLotteryId)
    if (success.status) {
      setGamePackages(success.gamePackages)
    }
  }

  const doGetDayAwards = async mainLotteryId => {
    const success = await getDayAwards(token, mainLotteryId)
    if (success.status) {
      setDayAwards(success.dayAwards)
    }
  }

  useEffect(() => {
    if (param.id && token) {
      doGetLottery(param.id)
      doGetGamePackages(param.id)
      doGetDayAwards(param.id)
    }
    doGetTimezones(token)
  }, [param, token])

  return (
    <React.Fragment>
      {/* <Menu /> */}
      {['developer', 'lotteries', 'admlotteries'].includes(rule) && (
        <main className="content">
          <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <h2 className="h4">
                  {post.id
                    ? `${i18n.t('edit')} ${i18n.t(post?.lottery?.toUpperCase())}`
                    : `${i18n.t('create')} ${i18n.t('lottery')}`}
                </h2>
              </div>
            </div>
            <div className="card p-2 mb-3">
              <div className="row">
                <div className="col-lg-8 mb-2">
                  <div className="input-group">
                    <div className="btn bg-dark text-light">{i18n.t('lottery')}</div>
                    <input
                      type="text"
                      className="form-control"
                      value={post.lottery}
                      onChange={e => setPost({ ...post, lottery: e.target.value })}
                      onBlur={e =>
                        setPost({
                          ...post,
                          lottery: e.target.value.toUpperCase(),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-4 mb-2">
                  <div className="input-group">
                    <div className="btn bg-dark text-light">{i18n.t('status')}</div>
                    <select
                      className="form-select"
                      value={post.status}
                      onChange={e => setPost({ ...post, status: e.target.value })}>
                      <option value={1}>{i18n.t('waiting')}</option>
                      <option value={4}>{i18n.t('confirmed')}</option>
                      <option value={6}>{i18n.t('canceled')}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <div className="border broder-dark p-2 rounded bg-dark h-100">
                    <div className="text-light mb-2">{i18n.t('balls').toUpperCase()}</div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="input-group">
                          <div className="btn bg-info text-light">{i18n.t('initial')}</div>
                          <input
                            type="number"
                            className="form-control"
                            min={0}
                            step={1}
                            value={post.ballsStart}
                            onChange={e =>
                              setPost({
                                ...post,
                                ballsStart: e.target.value < 0 ? 0 : e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-group">
                          <div className="btn bg-info text-light">{i18n.t('final')}</div>
                          <input
                            type="number"
                            className="form-control"
                            min={0}
                            step={1}
                            value={post.ballsFinish}
                            onChange={e =>
                              setPost({
                                ...post,
                                ballsFinish: e.target.value < 0 ? 0 : e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="border broder-dark p-2  rounded bg-dark h-100">
                    <div className="input-group mb-2">
                      <div className="btn bg-info text-light">{i18n.t('qtyToAward')}</div>
                      <input
                        type="number"
                        min={1}
                        step={1}
                        className="form-control"
                        value={post.qtyToAward}
                        onChange={e => setPost({ ...post, qtyToAward: e.target.value })}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <div className="btn bg-info text-light">{i18n.t('timezone')}</div>
                      <select
                        className="form-select"
                        value={post.timezone}
                        onChange={e => setPost({ ...post, timezone: e.target.value })}>
                        {timezones.map(timezone => (
                          <option key={timezone.id} value={timezone.id}>
                            {timezone.text}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="input-group mb-2"></div>
                    <div className="input-group mb-2">
                      <div className="btn bg-info text-light">{i18n.t('oddLimit')}</div>
                      <input
                        type="text"
                        className="form-control"
                        value={post.oddLimit}
                        onChange={e => setPost({ ...post, oddLimit: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className="card p-2 mb-3">
                  <div className="border broder-dark p-2  rounded bg-dark h-100">
                    <div className="input-group mb-2">
                      <div className="btn bg-info text-light">{i18n.t('ytb_channel_id')}</div>
                      <input
                        type="text"
                        min={1}
                        step={1}
                        className="form-control"
                        value={post.youtubeChannelId}
                        onChange={e =>
                          setPost({
                            ...post,
                            youtubeChannelId: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="card p-2 mb-3">
                  <div className="border broder-dark p-2  rounded bg-dark h-100">
                    <div className="input-group mb-2">
                      <div className="btn bg-info text-light">{i18n.t('Link do Youtube')}</div>
                      <input
                        type="text"
                        min={1}
                        step={1}
                        className="form-control"
                        value={post.youtubeLink}
                        onChange={e =>
                          setPost({
                            ...post,
                            youtubeLink: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="card p-2 mb-3">
                  <div className="border broder-dark p-2  rounded bg-dark h-100">
                    <div className="input-group mb-2">
                      <div className="btn bg-info text-light">{i18n.t('live_hour')}</div>
                      <input
                        type="time"
                        className="form-control"
                        value={post.liveHour}
                        onChange={e => {
                          setPost({ ...post, liveHour: e.target.value })
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {post.id && (
              <div className="card p-2 mb-3">
                <div className="bgame-0 shadow table-wrapper table-responsive">
                  <div className="d-flex">
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#modalDayAward"
                      onClick={() =>
                        doEditDayAward({
                          mainLotteryId: post.id,
                          dayWeek: 0,
                          hour: 0,
                          minutes: 0,
                        })
                      }>
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <i className="fas fa-plus me-2"></i>
                        {i18n.t('create')} {i18n.t('draw_day')}
                      </div>
                    </button>
                  </div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="bgame-gray-200">{i18n.t('day')}</th>
                        <th className="bgame-gray-200">{i18n.t('hour')}</th>
                        <th className="bgame-gray-200">{i18n.t('minutes')}</th>
                        <th className="bgame-gray-200">{i18n.t('action')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayAwards?.map((dayAward, di) => (
                        <DayAwardRow dayAward={dayAward} key={di} doEditDayAward={doEditDayAward} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {post.id && (
              <div className="card p-2 mb-3">
                <div className="bgame-0 shadow table-wrapper table-responsive">
                  <div className="d-flex">
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#modalGamePackage"
                      onClick={() =>
                        doEditGamePackage({
                          mainLotteryId: post.id,
                          qty: 10,
                          multiplier: 1000,
                          maxValue: 50,
                          limitRepeat: 0,
                        })
                      }>
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <i className="fas fa-plus me-2"></i>
                        {i18n.t('create')} {i18n.t('award_packages')}
                      </div>
                    </button>
                  </div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="bgame-gray-200">{i18n.t('qty')}</th>
                        <th className="bgame-gray-200">{i18n.t('multiplier')}</th>
                        <th className="bgame-gray-200">{i18n.t('maxValue')}</th>
                        <th className="bgame-gray-200">{i18n.t('limitRepeat')}</th>
                        <th className="bgame-gray-200">{i18n.t('action')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gamePackages?.map((gamePackage, gi) => (
                        <GamePackageRow gamePackage={gamePackage} key={gi} doEditGamePackage={doEditGamePackage} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className="d-flex gap-2 mb-3">
              <button className="btn btn-success" type="button" onClick={saveLottery}>
                <i className="fa fa-save me-2"></i>
                {i18n.t('save')}
              </button>
              <Link className="btn btn-info" to="/lotteries">
                <i className="fa fa-arrow-left me-2"></i>
                {i18n.t('back')}
              </Link>
            </div>
          </div>
        </main>
      )}
      <ModalDayAward editDayAward={editDayAward} doGetDayAwards={doGetDayAwards} />
      <ModalGamePackage editGamePackage={editGamePackage} doGetGamePackages={doGetGamePackages} />
    </React.Fragment>
  )
}
