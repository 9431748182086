import axios from './BaseService'

const GAMES_URL = `${process.env.REACT_APP_API_URL}/games/`

export async function getGames(token, page, limit, status) {
  const endPoint = `${GAMES_URL}listgames${page ? `?page=${page}` : ''}`
  const response = await axios.post(endPoint, { status, limit })
  return response.data
}

export async function getGame(id, token) {
  const endPoint = `${GAMES_URL}getgame/${id}`
  const response = await axios.get(endPoint)
  return response.data
}

export async function getBets(token, page, limit, search, status, searchUser) {
  const endPoint = `${GAMES_URL}listbets${page ? `?page=${page}` : ''}`
  const response = await axios.post(endPoint, { status, limit, search, searchUser })
  return response.data
}

export async function sendCloseAndNewGame(data, token) {
  const endPoint = `${GAMES_URL}closeandnewgame`
  const response = await axios.post(endPoint, data)
  return response.data
}

export async function sendEditGame(data, token) {
  const endPoint = `${GAMES_URL}sendeditgame`
  const response = await axios.post(endPoint, data)
  return response.data
}
