import React from 'react'
import { i18n } from '../../utils/translates/i18n'

export default function GamePackageRow({ gamePackage, doEditGamePackage }) {
  return (
    <>
      <tr>
        <td>{gamePackage.qty}</td>
        <td>{gamePackage.multiplier}</td>
        <td>{gamePackage.maxValue}</td>
        <td>{gamePackage.limitRepeat}</td>
        <td>
          <button
            className="btn btn-warning btn-sm my-0 py-1"
            data-bs-toggle="modal"
            data-bs-target="#modalGamePackage"
            onClick={() => doEditGamePackage(gamePackage)}>
            <i className="fa fa-edit me-2"></i>
            {i18n.t('edit')}
          </button>
        </td>
      </tr>
    </>
  )
}
