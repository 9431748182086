/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
// import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { getLotteries, getTimezone } from '../../services/LotteriesService.js'
import { i18n } from '../../utils/translates/i18n'
import LotteryRow from './LotteryRow.js'
import { decrypt } from '../../utils/crypto.js'

export default function Lotteries() {
  const rule = decrypt(sessionStorage.getItem('rule'))
  const defaultLocation = useLocation()
  const history = useHistory()

  const [lotteries, setLotteries] = useState([])
  const [timezones, setTimezones] = useState([])

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [status, setStatus] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)

  const getTimezones = async token => {
    const success = await getTimezone(token)
    if (success.status) {
      setTimezones(success.timezones)
    }
  }

  const doGetLotteries = async () => {
    const success = await getLotteries(token, page, lim, status)
    if (success.status) {
      setLotteries(success.list.rows)
      setCount(success.list.count)
    }
  }

  useEffect(() => {
    doGetLotteries()
  }, [token, page, lim, status])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  useEffect(() => {
    if (token) getTimezones(token)
  }, [token])

  return (
    <React.Fragment>
      {/* <Menu /> */}
      <main className="content">
        <div className="container-fluid">
          {/* <div className="alert bg-danger text-light fs-3 fw-bold mt-3">Página em Desenvolvimento. Em breve novas funcionalidades. Essa página é apenas um Preview.</div> */}
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <h2 className="h4">{i18n.t('lotteries')}</h2>
            </div>
          </div>
          <Pagination count={count} pageSize={lim}>
            <div className="align-items-center">
              <Link className="btn btn-primary" to="/lottery/create">
                <div style={{ whiteSpace: 'nowrap' }}>
                  <i className="fas fa-plus me-2"></i>
                  {i18n.t('create')} {i18n.t('lottery')}
                </div>
              </Link>
            </div>
            <div className="input-group mx-3">
              <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
                <option value="">{i18n.t('status')}</option>
                <option value={1}>{i18n.t('waiting')}</option>
                <option value={4}>{i18n.t('confirmed')}</option>
                <option value={6}>{i18n.t('canceled')}</option>
              </select>
            </div>
            <select className="form-select w-50" value={lim} onChange={e => setLims(e.target.value)}>
              <option className="bg-light" value="5">
                5 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="10">
                10 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="25">
                25 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="50">
                50 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="100">
                100 {i18n.t('registers')}
              </option>
            </select>
          </Pagination>
          <div className="card card-body bgame-0 shadow table-wrapper table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="bgame-gray-200">{i18n.t('picture')}</th>
                  <th className="bgame-gray-200">{i18n.t('lottery')}</th>
                  <th className="bgame-gray-200">{i18n.t('status')}</th>
                  <th className="bgame-gray-200">{i18n.t('timezone')}</th>
                  <th className="bgame-gray-200">{i18n.t('action')}</th>
                </tr>
              </thead>
              <tbody>
                {lotteries?.map((lottery, i) => (
                  <LotteryRow
                    lottery={lottery}
                    key={i}
                    timezones={timezones}
                    doGetLotteries={doGetLotteries}
                    rule={rule}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}
