/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
// import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { getTelegramIds } from '../../services/UsersService'
import { i18n } from '../../utils/translates/i18n'
import TelegramIdRow from './TelegramIdRow.js'
import { decrypt } from '../../utils/crypto.js'

function TelegramChat() {
  const rule = decrypt(sessionStorage.getItem('rule'))
  const defaultLocation = useLocation()
  const history = useHistory()

  const [telegramIds, setTelegramIds] = useState([])

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [search, setSearch] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)

  const doGetTelegramIds = async () => {
    const success = await getTelegramIds(token, page, lim, search)
    if (success.status) {
      setTelegramIds(success.list.rows)
      setCount(success.list.count)
    }
  }

  useEffect(() => {
    doGetTelegramIds()
  }, [token, page, lim, search])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  return (
    <React.Fragment>
      {/* <Menu /> */}
      {['developer', 'finance', 'support', 'manager', 'admlotteries'].includes(rule) && (
        <main className="content">
          <>
            <div className="container-fluid">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                  <h2 className="h4">{i18n.t('telegram-ids')}</h2>
                </div>
              </div>
              <Pagination count={count} pageSize={lim}>
                <div className="align-items-center">
                  <div className="alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center">
                    <i className="fas fa-search me-3"></i>
                  </div>
                </div>
                <div className="input-group mx-3">
                  <input
                    className="form-control"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={`${i18n.t('search')} ${i18n.t('by')} ${i18n.t('nick')}`}
                  />
                </div>
                <select className="form-select w-50" value={lim} onChange={e => setLims(e.target.value)}>
                  <option className="bg-light" value="5">
                    5 {i18n.t('registers')}
                  </option>
                  <option className="bg-light" value="10">
                    10 {i18n.t('registers')}
                  </option>
                  <option className="bg-light" value="25">
                    25 {i18n.t('registers')}
                  </option>
                  <option className="bg-light" value="50">
                    50 {i18n.t('registers')}
                  </option>
                  <option className="bg-light" value="100">
                    100 {i18n.t('registers')}
                  </option>
                </select>
              </Pagination>
              <div className="card card-body btelegramId-0 shadow table-wrapper table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="btelegramId-gray-200">{i18n.t('id')}</th>
                      <th className="btelegramId-gray-200">{i18n.t('firstName')}</th>
                      <th className="btelegramId-gray-200">{i18n.t('lastName')}</th>
                      <th className="btelegramId-gray-200">{i18n.t('date')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {telegramIds?.map((t, i) => (
                      <TelegramIdRow t={t} key={i} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        </main>
      )}
    </React.Fragment>
  )
}

export default TelegramChat
