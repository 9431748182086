import { useEffect, useRef, useState } from 'react'
import { i18n } from '../../utils/translates/i18n'
import { FormateDate, toCapitalize } from '../../utils/CustomFormatations'
import { sendCloseAndNewGame } from '../../services/GamesService'
import { toast } from 'react-toastify'
import { zeroPad } from 'react-countdown'

export default function ModalGameDetails({ editDetails, doGetGames, timezones }) {
  const token = sessionStorage.getItem('token')
  const btnClose = useRef('')
  const timeLimit = 10
  const [resume, setResume] = useState(false)
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

  const DEFAULT_POST = {
    id: '',
    lottery: '',
    oldDrawDate: '',
    drawDate: '',
    drawHours: 0,
    drawMinutes: 0,
    drawDue: '',
    dueHours: 0,
    dueMinutes: 0,
    balls: '',
    specialBalls: '1',
  }

  const [post, setPost] = useState(DEFAULT_POST)
  const [closeGame, setCloseGame] = useState(false)

  const [maxBalls, setMaxBalls] = useState(0)
  const [arrBalls, setArrBalls] = useState([])
  const [choosedBalls, setChoosedBalls] = useState([])

  const createArrayBalls = () => {
    setArrBalls([])
    for (
      let i = parseInt(editDetails.mainLottery?.ballsStart);
      i <= parseInt(editDetails.mainLottery?.ballsFinish);
      i++
    )
      setArrBalls(p => (!p.includes(i) ? [...p, i] : p))
  }

  const doCloseGame = async () => {
    const success = await sendCloseAndNewGame(post, token)
    if (success.status) {
      doGetGames()
      setResume(false)
      setPost(DEFAULT_POST)
      toast.success('Jogo fechado com sucesso!')
    } else {
      toast.error(i18n.t(success.msg))
      setChoosedBalls([])
      setCloseGame(false)
      setPost(DEFAULT_POST)
      setResume(false)
    }
    btnClose.current.click()
  }

  useEffect(() => {
    createArrayBalls()
    setResume(false)
    setMaxBalls(editDetails.mainLottery?.qtyToAward)
    setChoosedBalls([])
    setCloseGame(false)
  }, [editDetails.id])

  useEffect(() => {
    setPost({
      ...post,
      id: editDetails.id,
      lottery: editDetails,
      oldDrawDate: editDetails.drawDate,
      balls: choosedBalls.join(','),
    })
  }, [choosedBalls])

  return (
    <div
      className="modal fade"
      id="modalGameDetails"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {`${i18n.t('details')} ${i18n.t(editDetails?.mainLottery?.lottery?.toUpperCase())} # ${i18n.t(
                editDetails?.id
              )}`}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            {!closeGame ? (
              <>
                <div className="col-12 mb-2">
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="bg-dark text-light p-2">{i18n.t('draw_date')}:</div>
                    <div className="flex-fill border border-2 border-dark p-2">
                      {FormateDate(editDetails.drawDate, true, true)}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="bg-dark text-light p-2">{i18n.t('draw_due')}:</div>
                    <div className="flex-fill border border-2 border-dark p-2">
                      {FormateDate(editDetails.drawDue, true, true)}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <div className="btn bg-dark text-light">{toCapitalize(`${i18n.t('drawn_numbers')}`)}</div>
                  <div className="card-body border border-2 border-dark">
                    <div className="d-flex flex-wrap gap-2">
                      {!!arrBalls.length &&
                        arrBalls.map((item, index) => (
                          <button
                            className={`btn ${
                              !choosedBalls.includes(item) ? 'bg-warning' : 'bg-success'
                            } btn-sm rounded-circle border border-2`}
                            onClick={() =>
                              setChoosedBalls(p =>
                                !p.includes(item) ? (p.length < maxBalls ? [...p, item] : p) : p.filter(v => v !== item)
                              )
                            }
                            style={{ width: '40px', height: '40px' }}
                            key={index}>
                            {item}
                          </button>
                        ))}
                    </div>
                  </div>
                </div>
                {maxBalls === choosedBalls.length && (
                  <div className="col-12 mb-2">
                    <button className="btn btn-primary" onClick={() => setCloseGame(true)}>
                      {i18n.t('close_game')}
                    </button>
                  </div>
                )}
              </>
            ) : !resume ? (
              <>
                <div className="card-title p-2 bg-info">
                  <span>{i18n.t('next_draw')}:</span>
                  <br />
                </div>
                <div className="alert bg-warning">
                  {`${timezones?.filter(t => t.id == editDetails?.mainLottery?.timezone)[0]?.text}`}
                  <ul className="list-group list-group-flush">
                    {editDetails?.mainLottery?.dayAwards?.map((d, i) => (
                      <li className="list-group-item" key={i}>{`${i18n.t(days[d.dayWeek])}, ${zeroPad(
                        d.hour,
                        2
                      )}:${zeroPad(d.minutes, 2)}`}</li>
                    ))}
                  </ul>
                </div>

                <div className="col-12 bg-dark mb-2 rounded p-2">
                  <div className="input-group mb-2">
                    <div className="btn bg-dark text-light">{i18n.t('draw_date')}</div>
                    <input
                      type="date"
                      className="form-control"
                      value={post.drawDate}
                      onChange={e => setPost({ ...post, drawDate: e.target.value })}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group mb-2">
                        <div className="btn bg-dark text-light">{i18n.t('hour')}</div>
                        <select
                          className="form-select"
                          value={post.drawHours}
                          onChange={e => setPost({ ...post, drawHours: e.target.value })}>
                          {[...Array(24)].map((x, i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-2">
                        <div className="btn bg-dark text-light">{i18n.t('minutes')}</div>
                        <select
                          className="form-select"
                          value={post.drawMinutes}
                          onChange={e => setPost({ ...post, drawMinutes: e.target.value })}>
                          {[...Array(60)].map((x, i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-2 bg-dark rounded p-2">
                  <div className="input-group mb-2">
                    <div className="btn bg-dark text-light">{i18n.t('draw_due')}</div>
                    <input
                      type="date"
                      className="form-control"
                      value={post.drawDue}
                      onChange={e => setPost({ ...post, drawDue: e.target.value })}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group mb-2">
                        <div className="btn bg-dark text-light">{i18n.t('hour')}</div>
                        <select
                          className="form-select"
                          value={post.dueHours}
                          onChange={e => setPost({ ...post, dueHours: e.target.value })}>
                          {[...Array(24)].map((x, i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-2">
                        <div className="btn bg-dark text-light">{i18n.t('minutes')}</div>
                        <select
                          className="form-select"
                          value={post.dueMinutes}
                          onChange={e => setPost({ ...post, dueMinutes: e.target.value })}>
                          {[...Array(60)].map((x, i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {post.drawDate && post.drawDue && (
                  <div className="col-12 mb-2">
                    <button className="btn btn-success" onClick={() => setResume(true)}>
                      {i18n.t('save')}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="card-body">
                  <ul className="list-group list-group-stripped">
                    <li className="list-group-item">
                      <strong>{i18n.t('draw_date')}: </strong>
                      {`${FormateDate(post.oldDrawDate)}`}
                    </li>
                    <li className="list-group-item">
                      {toCapitalize(`${i18n.t('drawn_numbers')}`)}:
                      <div className="d-flex flex-wrap justify-content-start gap-2">
                        {post.balls.split(',').map((ball, bix) => (
                          <span
                            className={`d-flex align-items-center justify-content-center border border-primary rounded-circle text-dark fw-bold bg-light`}
                            style={{ width: '30px', height: '30px' }}
                            key={bix}>
                            {!!ball && ball.length > 1 ? ball : `0${ball}`}
                          </span>
                        ))}
                      </div>
                    </li>
                    <br />
                    <div className="bg-dark p-2 rounded">{i18n.t('next_draw')}</div>
                    <li className="list-group-item">
                      <strong>{i18n.t('draw_date')}: </strong>
                      {`${FormateDate(post.drawDate)}, ${zeroPad(post.drawHours, 2)}:${zeroPad(post.drawMinutes, 2)}`}
                    </li>
                    <li className="list-group-item">
                      <strong>{i18n.t('draw_due')}: </strong>
                      {`${FormateDate(post.drawDue)}, ${zeroPad(post.dueHours, 2)}:${zeroPad(post.dueMinutes, 2)}`}
                    </li>
                  </ul>

                  <div className="d-flex gap-3">
                    <button className="btn btn-success" onClick={doCloseGame}>
                      {i18n.t('confirm_approve')}
                    </button>
                    <button className="btn btn-danger" onClick={() => setResume(false)}>
                      {i18n.t('back')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* <pre>{JSON.stringify(post, null, 2)}</pre> */}
        </div>
      </div>
    </div>
  )
}
