import React, { useEffect, useRef, useState } from 'react'
import { i18n } from '../../utils/translates/i18n'
import { Link } from 'react-router-dom'
import { RemoveSpaceAccents } from '../../utils/CustomFormatations'
import { editImg } from '../../services/LotteriesService'
import { toast } from 'react-toastify'

export default function LotteryRow({ lottery, timezones, doGetLotteries, rule }) {
  const [file, setFile] = useState(null)
  const btnFile = useRef('')
  const token = sessionStorage.getItem('token')

  const doUpload = async () => {
    const formData = new FormData()
    let fileName = file.name.split('.')
    let extension = fileName[fileName.length - 1]
    formData.append(
      'file',
      file,
      `${new Date().getTime()}_${RemoveSpaceAccents(fileName.slice(0, -1).join('_'))}.${extension}`
    )

    const result = await editImg(token, lottery.id, formData)
    if (result.status) {
      toast.success(i18n.t('lottery_updated'))
      if (doGetLotteries) doGetLotteries()
    }
  }
  useEffect(() => {
    if (file) doUpload()
  }, [file])

  return (
    <>
      <tr>
        <td>
          <>
            <div className={`text-center`} style={{ cursor: 'pointer' }} onClick={() => btnFile.current.click()}>
              <img
                src={
                  file
                    ? URL.createObjectURL(file)
                    : !!lottery.img
                    ? `${process.env.REACT_APP_API_URL}/lotteries/${lottery.img}`
                    : '/logos/logo.png'
                }
                crossOrigin="anonymous"
                alt="lottery"
                className={`img-fluid`}
                style={{ width: 'auto', height: '30px' }}
              />
            </div>
            <div className="text-center">
              <input
                type="file"
                id="file"
                ref={btnFile}
                className="d-none"
                onChange={e => setFile(e.target.files[0])}
              />
            </div>
          </>
        </td>
        <td>{lottery.lottery?.toUpperCase()}</td>
        <td>
          <span className={`p-2 rounded bg-${lottery.statuses?.bg}`}>{i18n.t(lottery.statuses?.status)}</span>
        </td>
        <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {timezones.filter(t => t.id == lottery.timezone)[0]?.text}
        </td>
        <td>
          {['developer', 'lotteries','admlotteries'].includes(rule) && (
            <Link className="btn btn-warning me-2" to={`/lottery/edit/${lottery.id}`}>
              <i className="fa fa-edit me-2"></i>
              {i18n.t('edit')}
            </Link>
          )}
          <Link className="btn btn-info me-2" to={`/lottery/results/${lottery.id}`}>
            <i className="fa fa-edit me-2"></i>
            {i18n.t('results')}
          </Link>
        </td>
      </tr>
    </>
  )
}
