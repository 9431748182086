/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
// import Menu from '../../components/Menu/Menu'
import { i18n } from '../../utils/translates/i18n'
import { useLocation, useParams, useHistory, Link } from 'react-router-dom'
import { getResults } from '../../services/LotteriesService'
import Pagination from '../../utils/pagination/Pagination'
import ResultRow from './ResultRow'

export default function ResultsLottery() {
  const param = useParams()
  const defaultLocation = useLocation()
  const token = sessionStorage.getItem('token')
  const history = useHistory()

  const [results, setResults] = useState([])

  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)

  const doGetResults = async mainLotteryId => {
    const success = await getResults(token, page, lim, mainLotteryId)
    if (success.status) {
      setResults(success.list.rows)
      setCount(success.list.count)
    }
  }

  useEffect(() => {
    if (param.id && token) doGetResults(param.id)
  }, [param, token, page, lim])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  return (
    <React.Fragment>
      {/* <Menu /> */}
      <main className="content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <h2 className="h4">
                {`${i18n.t('results')} ${i18n.t(results[0]?.mainLottery?.lottery?.toUpperCase())}`}
              </h2>
            </div>
          </div>
          <Pagination count={count} pageSize={lim}>
            <div className="align-items-center">
              <div className="bg-dark text-white p-2 rounded me-3" style={{ whiteSpace: 'nowrap' }}>
                <i className="fas fa-trophy me-2"></i>
                {i18n.t('results')}
              </div>
            </div>

            <img
              src={`${process.env.REACT_APP_API_URL}/lotteries/${results[0]?.mainLottery?.img}`}
              crossOrigin="anonymous"
              alt="lottery"
              className="img-fluid me-3"
              style={{ width: 'auto', height: '40px' }}
            />

            <select className="form-select w-50" value={lim} onChange={e => setLims(e.target.value)}>
              <option className="bg-light" value="5">
                5 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="10">
                10 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="25">
                25 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="50">
                50 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="100">
                100 {i18n.t('registers')}
              </option>
            </select>
          </Pagination>
          <div className="card card-body bgame-0 shadow table-wrapper table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="bgame-gray-200">{i18n.t('lottery')}</th>
                  <th className="bgame-gray-200">{i18n.t('draw_date')}</th>
                  <th className="bgame-gray-200">{i18n.t('balls')}</th>
                </tr>
              </thead>
              <tbody>
                {results?.map((result, i) => (
                  <ResultRow result={result} key={i} />
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex flex-wrap mt-3">
            <Link className="btn btn-info" to="/lotteries">
              <i className="fa fa-arrow-left me-2"></i>
              {i18n.t('back')}
            </Link>
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}
